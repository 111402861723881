import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { AutoSizer, Column, Table } from "react-virtualized";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default class MuiTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 34,
    rowHeight: 34,
  };

  getRowClassName = ({ index }) => {
    const { classes } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      "odd-row": index % 2,
      "matched-row": index < this.props.matchedRowsCount && index !== -1,
    });
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={
          (columnIndex != null && columns[columnIndex].numeric) || false
            ? "right"
            : "left"
        }
      >
        {<div dangerouslySetInnerHTML={{ __html: cellData }} />}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex, sortBy, sortDirection }) => {
    const { headerHeight, columns, classes } = this.props;
    const isSortActive =
      (columnIndex === 0 && sortBy === "gameTitleAsLink") ||
      (columnIndex === 1 && sortBy === "userNamesAsLinks");
    const direction = sortDirection === "ASC" ? "asc" : "desc";
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? "right" : "left"}
      >
        <TableSortLabel active={isSortActive} direction={direction}>
          <span>{label}</span>
        </TableSortLabel>
      </TableCell>
    );
  };

  render() {
    const {
      classes,
      columns,
      rowHeight,
      headerHeight,
      sortBy,
      sortDirection,
      ...tableProps
    } = this.props;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            className={classes.table}
            rowClassName={this.getRowClassName}
            sortBy={sortBy}
            sortDirection={sortDirection}
            {...tableProps}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: index,
                      sortBy,
                      sortDirection,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.cellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}
