import "./App.css";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Page from "./Page";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#37474f",
    },
    secondary: {
      main: "#424242",
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Page />
      </ThemeProvider>
    </div>
  );
}

export default App;
